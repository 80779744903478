.SignUpform{
    direction: rtl;
    font-family: "BYekan";
    padding: 0px;
    margin: 0px;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.SignUpform_img{
    /*height: "200px",
    display: "flex",
    width:"360px",
    justifyContent: "center",
    marginTop: "-5px",
    marginBottom: "-26.5px",
    marginLeft:"375px"*/
    /*height: 200px;
    display: flex;
    width: 360px;
    justify-content: center;
    margin-top: -50px;
    margin-bottom: -26.5px;
    margin-left:375px ;*/

    height: 200px;
    width: 85%;
    margin-bottom: 1px;
    margin-right: 6px;
    margin-top: 2px;
    margin-bottom: -26.5px;
}
.SignUpform_form{
    width:400px;
    background: #fff;    
    border: 1px solid #ccc;
    padding-right:25px;
    padding-left:20px;
    padding-top: 20px;
    box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.4);
    border-radius: 5px; 
}
.loading-signup {
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 600px)
  {
      .SignUpform_form{
          width:90%;
          
      }
      .SignUpform{ 
         /* margin-bottom: 10px;*/
      }
  } 