
.showarticleuser_fa{
    margin-right: 15%;
    margin-left: 15%;
}
@media (max-width: 1200px ) and (min-width:900px) {
    .showarticleuser_fa{
        margin-right: 12%;
        margin-left: 12%;
    }
}

@media (max-width: 900px ) and (min-width:600px) {
    .showarticleuser_fa{
        margin-right: 10%;
        margin-left: 10%;
    }
}

@media (max-width: 600px){
    .showarticleuser_fa{
        margin-right: 5%;
        margin-left: 5%;
    }
}