.Homepage_container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     color: #fff; 
    text-align: center;
    margin-bottom: 76px;
    direction: rtl;
    /* margin-top:57px; */
}
.home_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}
.home_title{
    font-size: 48px;
    font-weight: 800;
}
.home-banner__start-btn{
    font-family: 'BYekan';
    height: 56px;
    width: 256px;
    color: #fff;
    padding: 16px;
    font-size: 18px;
    font-weight: 800;
    border-radius: 32px;
    box-shadow: 0 0 0 6px rgba(30, 47, 151, 0.2);
    border: none;
    background-color: #1DA7EC;
    margin-top: 32px;
    cursor: pointer;
}
.home-banner__start-btn:hover {    
    box-shadow: 0 0 0 6px rgba(26, 167, 236, 0.5);
}
.home_info{
    margin-top: 30px;
    font-size: 24px;
}
.home_info1{
    margin-top: 20px;
    font-size: 24px;
}
@media (max-width: 600px) {
    .home-banner__start-btn{
        width: 300px;
    }
}
@media (max-width: 400px) {
    .home-banner__start-btn{
        width: 220px;
    }
    .home_info{
        font-size: 17px;
    }
    .home_info1{
        font-size: 17px;
    }
    .home_title{
        font-size: 38px;
    }
}

