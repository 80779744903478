.footer_ti{
  font-size: 20px;
  font-weight: 600;
  color: aliceblue;
}

.footer_social{
margin-top: 35px;
}

.footer_ajza{
  /* width:"50px", height:"50px" */
  width: 45px;
  height: 45px;
  margin-right: 17px;
}

@media (max-width: 600px)
{

  .footer_ti{
    font-size: 18px;
    font-weight: 600;
    color: aliceblue;
  }
  
  .footer_social{
  margin-top: 35px;
  }
  
  .footer_ajza{
    /* width:"50px", height:"50px" */
    width: 35px;
    height: 35px;
    margin-right: 17px;
  }
} 