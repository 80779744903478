.categories-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 86px;
}

.categories-section__title{
    font-size: 24px;
    font-weight: 800;
    color: black;
    padding: 8px;
}

.categories-section__highlight{
    color: #ff979d;
}

.categories-section__description{
    padding: 8px;
    font-size: 20px;
    font-weight: 700;
    color:black;
    margin-bottom: 16px;
}

.categories-section__items{
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 2%-20px;
    margin-left: 2%;
}

.Catgory_button{
   /* marginRight: "30px",
    fontWeight: 800,
    fontSize:"20px",
    width: "180px",
    height: "50px",*/

    margin-right:30px ;
    font-weight: 800;
    font-size: 20px;
    width:180px ;
    height: 50px;
    cursor: pointer;
}

@media (max-width: 600px)
{
    .Catgory_button{     
         margin-right:18px ;
         font-weight: 700;
         font-size: 18px;
         width:150px ;
         height: 45px;
     }
     .categories-section__items{
        margin-right: 2%-10px;
        margin-left: 2%;
    }
} 

@media (max-width: 500px)
{
    .Catgory_button{     
         margin-right:15px ;
         font-weight: 650;
         font-size: 17px;
         width:120px ;
         height: 45px;
     }
     .categories-section__items{
        margin-right: 1%-14px; 
         margin-left: 1%;
    }
} 

@media (max-width: 400px)
{
    .Catgory_button{     
         margin-right:13px ;
         font-weight: 600;
         font-size: 16px;
         width:110px ;
         height: 45px;
     }
     .categories-section__items{
        margin-right: 1%-13px;
        margin-left: 1%;
    }
} 
